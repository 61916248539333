@import url("https://fonts.googleapis.com/css?family=Changa&display=swap");

body,
html {
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.full,
html {
  display: block;
  height: 100%;
  width: 100%;
}

#canvas {
  transition: filter 500ms ease-in-out;
}

#canvas.won {
  filter: hue-rotate(180deg) brightness(5) contrast(150%);
}

#canvas.lost {
  filter: sepia(60%) blur(5px) contrast(60%) brightness(0.7);
}

.end {
  opacity: 0;
  visibility: hidden;
  filter:
    visibility 1s,
    opacity 1s;
}

#end {
  filter: sepia(60%) contrast(60%);

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.end.visible {
  opacity: 1;
  visibility: visible;
}

#end-content {
  min-width: 50vw;
  background: #725252;
  color: white;
  padding: 1rem;
  box-sizing: border-box;
}

#end-title {
  font-family: "Changa", sans-serif;
  font-size: 6vh;
  background: inherit;
  margin: 1rem;
  padding: 1rem;
  border-radius: 0.4rem;
  filter: brightness(0.8);
}

#end-footer {
  display: flex;
  justify-content: space-between;
  background: inherit;
}

#end-score-container {
  font-size: 1.9rem;
  margin: 1rem;
  padding: 1rem;
}

button#play-again {
  color: white;
  border: 1px solid white;
  padding: 1rem;
  margin: 1rem;
  font-size: 1.7rem;
  background: inherit;
  transition: filter 0.5s ease-out;

  &:hover {
    filter: brightness(1.5);
  }
}
